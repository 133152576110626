import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "fv-row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6" }
const _hoisted_5 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10",
  for: "kt_create_account_form_account_type_personal"
}
const _hoisted_6 = { class: "svg-icon svg-icon-3x me-5" }
const _hoisted_7 = { class: "col-lg-6" }
const _hoisted_8 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center",
  for: "kt_create_account_form_account_type_corporate"
}
const _hoisted_9 = { class: "svg-icon svg-icon-3x me-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"pb-10 pb-lg-15\"><h2 class=\"fw-bolder d-flex align-items-center text-dark\"> Choose Account Type <i class=\"fas fa-exclamation-circle ms-2 fs-7\" data-bs-toggle=\"tooltip\" title=\"Billing is issued based on your selected account type\"></i></h2><div class=\"text-gray-400 fw-bold fs-6\"> If you need more info, please check out <a href=\"#\" class=\"link-primary fw-bolder\">Help Page</a>. </div></div>", 1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Field, {
            type: "radio",
            class: "btn-check",
            name: "accountType",
            value: "personal",
            id: "kt_create_account_form_account_type_personal"
          }),
          _createElementVNode("label", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com005.svg" })
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "d-block fw-bold text-start" }, [
              _createElementVNode("span", { class: "text-dark fw-bolder d-block fs-4 mb-2" }, " Personal Account "),
              _createElementVNode("span", { class: "text-gray-400 fw-bold fs-6" }, "If you need more info, please check it out")
            ], -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Field, {
            type: "radio",
            class: "btn-check",
            name: "accountType",
            value: "corporate",
            id: "kt_create_account_form_account_type_corporate"
          }),
          _createElementVNode("label", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin006.svg" })
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "d-block fw-bold text-start" }, [
              _createElementVNode("span", { class: "text-dark fw-bolder d-block fs-4 mb-2" }, "Corporate Account"),
              _createElementVNode("span", { class: "text-gray-400 fw-bold fs-6" }, "Create corporate account to mane users")
            ], -1))
          ])
        ]),
        _createVNode(_component_ErrorMessage, { name: "accountType" })
      ])
    ])
  ]))
}