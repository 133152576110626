import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_4 = { class: "d-flex flex-column mb-7 fv-row" }
const _hoisted_5 = { class: "position-relative" }
const _hoisted_6 = { class: "row mb-10" }
const _hoisted_7 = { class: "col-md-8 fv-row" }
const _hoisted_8 = { class: "row fv-row" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = ["label", "value"]
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = ["label", "value"]
const _hoisted_13 = { class: "col-md-4 fv-row" }
const _hoisted_14 = { class: "position-relative" }
const _hoisted_15 = { class: "position-absolute translate-middle-y top-50 end-0 me-3" }
const _hoisted_16 = { class: "svg-icon svg-icon-2hx" }
const _hoisted_17 = { class: "d-flex flex-stack" }
const _hoisted_18 = { class: "form-check form-switch form-check-custom form-check-solid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "pb-10 pb-lg-15" }, [
      _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Billing Details"),
      _createElementVNode("div", { class: "text-gray-400 fw-bold fs-6" }, [
        _createTextVNode(" If you need more info, please check out "),
        _createElementVNode("a", {
          href: "#",
          class: "text-primary fw-bolder"
        }, "Help Page"),
        _createTextVNode(". ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
          _createElementVNode("span", { class: "required" }, "Name On Card"),
          _createElementVNode("i", {
            class: "fas fa-exclamation-circle ms-2 fs-7",
            "data-bs-toggle": "tooltip",
            title: "Specify a card holder's name"
          })
        ], -1)),
        _createVNode(_component_Field, {
          type: "text",
          class: "form-control form-control-solid",
          placeholder: "",
          name: "nameOnCard"
        }),
        _createVNode(_component_ErrorMessage, {
          class: "fv-plugins-message-container invalid-feedback",
          name: "nameOnCard"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[2] || (_cache[2] = _createElementVNode("label", { class: "required fs-6 fw-bold form-label mb-2" }, "Card Number", -1)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Field, {
            type: "text",
            class: "form-control form-control-solid",
            placeholder: "Enter card number",
            name: "cardNumber"
          }),
          _createVNode(_component_ErrorMessage, {
            class: "fv-plugins-message-container invalid-feedback",
            name: "cardNumber"
          }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "position-absolute translate-middle-y top-50 end-0 me-5" }, [
            _createElementVNode("img", {
              src: "media/svg/card-logos/visa.svg",
              alt: "",
              class: "h-25px"
            }),
            _createElementVNode("img", {
              src: "media/svg/card-logos/mastercard.svg",
              alt: "",
              class: "h-25px"
            }),
            _createElementVNode("img", {
              src: "media/svg/card-logos/american-express.svg",
              alt: "",
              class: "h-25px"
            })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[3] || (_cache[3] = _createElementVNode("label", { class: "required fs-6 fw-bold form-label mb-2" }, "Expiration Date", -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Field, {
                name: "cardExpiryMonth",
                class: "form-select form-select-solid select2-hidden-accessible",
                placeholder: "Month",
                as: "select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
                    return _createElementVNode("option", {
                      key: i,
                      label: i,
                      value: i
                    }, null, 8, _hoisted_10)
                  }), 64))
                ]),
                _: 1
              }),
              _createVNode(_component_ErrorMessage, {
                class: "fv-plugins-message-container invalid-feedback",
                name: "cardExpiryMonth"
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Field, {
                name: "cardExpiryYear",
                class: "form-select form-select-solid select2-hidden-accessible",
                placeholder: "Year",
                as: "select"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                    return _createElementVNode("option", {
                      key: i,
                      label: i + (new Date().getFullYear() - 1),
                      value: i
                    }, null, 8, _hoisted_12)
                  }), 64))
                ]),
                _: 1
              }),
              _createVNode(_component_ErrorMessage, {
                class: "fv-plugins-message-container invalid-feedback",
                name: "cardExpiryYear"
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }, [
            _createElementVNode("span", { class: "required" }, "CVV"),
            _createElementVNode("i", {
              class: "fas fa-exclamation-circle ms-2 fs-7",
              "data-bs-toggle": "tooltip",
              title: "Enter a card CVV code"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_Field, {
              type: "text",
              class: "form-control form-control-solid",
              minlength: "3",
              maxlength: "4",
              placeholder: "CVV",
              name: "cardCvv"
            }),
            _createVNode(_component_ErrorMessage, {
              class: "fv-plugins-message-container invalid-feedback",
              name: "cardCvv"
            }),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin002.svg" })
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "me-5" }, [
          _createElementVNode("label", { class: "fs-6 fw-bold form-label" }, "Save Card for further billing?"),
          _createElementVNode("div", { class: "fs-7 fw-bold text-gray-400" }, " If you need more info, please check budget planning ")
        ], -1)),
        _createElementVNode("label", _hoisted_18, [
          _createVNode(_component_Field, {
            type: "checkbox",
            class: "form-check-input",
            name: "saveCard",
            value: "1"
          }),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "form-check-label fw-bold text-gray-400" }, " Save Card ", -1))
        ])
      ])
    ])
  ]))
}