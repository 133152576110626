import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pb-8 pb-lg-10" }
const _hoisted_3 = { class: "text-gray-400 fw-bold fs-6" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "notice d-flex bg-light-warning rounded border-warning border border-dashed p-6" }
const _hoisted_6 = { class: "svg-icon svg-icon-2tx svg-icon-warning me-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Your Are Done!", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createTextVNode(" If you need more info, please ")),
        _createVNode(_component_router_link, {
          to: "/sign-in",
          class: "link-primary fw-bolder"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Sign In")
          ])),
          _: 1
        }),
        _cache[2] || (_cache[2] = _createTextVNode(". "))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fs-6 text-gray-600 mb-5" }, " Writing headlines for blog posts is as much an art as it is a science and probably warrants its own post, but for all advise is with what works for your great & amazing audience. ", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", _hoisted_6, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen044.svg" })
        ]),
        _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"d-flex flex-stack flex-grow-1\"><div class=\"fw-bold\"><h4 class=\"text-gray-800 fw-bolder\">We need your attention!</h4><div class=\"fs-6 text-gray-600\"> To start using great tools, please, please <a href=\"#\" class=\"fw-bolder\">Create Team Platform</a></div></div></div>", 1))
      ])
    ])
  ]))
}