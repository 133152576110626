import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "mb-10 fv-row" }
const _hoisted_3 = {
  class: "row mb-2",
  "data-kt-buttons": "true"
}
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 active" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4" }
const _hoisted_12 = { class: "mb-10 fv-row" }
const _hoisted_13 = { class: "mb-0 fv-row" }
const _hoisted_14 = { class: "mb-0" }
const _hoisted_15 = { class: "d-flex flex-stack mb-5 cursor-pointer" }
const _hoisted_16 = { class: "d-flex align-items-center me-2" }
const _hoisted_17 = { class: "symbol symbol-50px me-6" }
const _hoisted_18 = { class: "symbol-label" }
const _hoisted_19 = { class: "svg-icon svg-icon-1 svg-icon-gray-600" }
const _hoisted_20 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_21 = { class: "d-flex flex-stack mb-5 cursor-pointer" }
const _hoisted_22 = { class: "d-flex align-items-center me-2" }
const _hoisted_23 = { class: "symbol symbol-50px me-6" }
const _hoisted_24 = { class: "symbol-label" }
const _hoisted_25 = { class: "svg-icon svg-icon-1 svg-icon-gray-600" }
const _hoisted_26 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_27 = { class: "d-flex flex-stack mb-0 cursor-pointer" }
const _hoisted_28 = { class: "d-flex align-items-center me-2" }
const _hoisted_29 = { class: "symbol symbol-50px me-6" }
const _hoisted_30 = { class: "symbol-label" }
const _hoisted_31 = { class: "svg-icon svg-icon-1 svg-icon-gray-600" }
const _hoisted_32 = { class: "form-check form-check-custom form-check-solid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "pb-10 pb-lg-15" }, [
      _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Account Info"),
      _createElementVNode("div", { class: "text-gray-400 fw-bold fs-6" }, [
        _createTextVNode(" If you need more info, please check out "),
        _createElementVNode("a", {
          href: "#",
          class: "link-primary fw-bolder"
        }, "Help Page"),
        _createTextVNode(". ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "d-flex align-items-center form-label mb-3" }, [
        _createTextVNode(" Specify Team Size "),
        _createElementVNode("i", {
          class: "fas fa-exclamation-circle ms-2 fs-7",
          "data-bs-toggle": "tooltip",
          title: "Provide your team size to help us setup your billing"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, [
            _createVNode(_component_Field, {
              type: "radio",
              class: "btn-check",
              name: "accountTeamSize",
              value: "1-1"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fw-bolder fs-3" }, "1-1", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, [
            _createVNode(_component_Field, {
              type: "radio",
              class: "btn-check",
              name: "accountTeamSize",
              value: "2-10"
            }),
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "fw-bolder fs-3" }, "2-10", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, [
            _createVNode(_component_Field, {
              type: "radio",
              class: "btn-check",
              name: "accountTeamSize",
              value: "10-50"
            }),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-bolder fs-3" }, "10-50", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, [
            _createVNode(_component_Field, {
              type: "radio",
              class: "btn-check",
              name: "accountTeamSize",
              checked: "",
              value: "50+"
            }),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "fw-bolder fs-3" }, "50+", -1))
          ])
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-text" }, " Customers will see this shortened version of your statement descriptor ", -1))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label mb-3" }, "Team Account Name", -1)),
      _createVNode(_component_Field, {
        type: "text",
        class: "form-control form-control-lg form-control-solid",
        name: "accountName",
        placeholder: "",
        value: ""
      }),
      _createVNode(_component_ErrorMessage, {
        name: "accountName",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[10] || (_cache[10] = _createElementVNode("label", { class: "d-flex align-items-center form-label mb-5" }, [
        _createTextVNode(" Select Account Plan "),
        _createElementVNode("i", {
          class: "fas fa-exclamation-circle ms-2 fs-7",
          "data-bs-toggle": "tooltip",
          title: "Monthly billing will be based on your account plan"
        })
      ], -1)),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", _hoisted_15, [
          _createElementVNode("span", _hoisted_16, [
            _createElementVNode("span", _hoisted_17, [
              _createElementVNode("span", _hoisted_18, [
                _createElementVNode("span", _hoisted_19, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/finance/fin001.svg" })
                ])
              ])
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "d-flex flex-column" }, [
              _createElementVNode("span", { class: "fw-bolder text-gray-800 text-hover-primary fs-5" }, "Company Account"),
              _createElementVNode("span", { class: "fs-6 fw-bold text-gray-400" }, "Use images to enhance your post flow")
            ], -1))
          ]),
          _createElementVNode("span", _hoisted_20, [
            _createVNode(_component_Field, {
              class: "form-check-input",
              type: "radio",
              name: "accountPlan",
              value: "1"
            })
          ])
        ]),
        _createElementVNode("label", _hoisted_21, [
          _createElementVNode("span", _hoisted_22, [
            _createElementVNode("span", _hoisted_23, [
              _createElementVNode("span", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra006.svg" })
                ])
              ])
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "d-flex flex-column" }, [
              _createElementVNode("span", { class: "fw-bolder text-gray-800 text-hover-primary fs-5" }, "Developer Account"),
              _createElementVNode("span", { class: "fs-6 fw-bold text-gray-400" }, "Use images to your post time")
            ], -1))
          ]),
          _createElementVNode("span", _hoisted_26, [
            _createVNode(_component_Field, {
              class: "form-check-input",
              type: "radio",
              checked: "",
              name: "accountPlan",
              value: "2"
            })
          ])
        ]),
        _createElementVNode("label", _hoisted_27, [
          _createElementVNode("span", _hoisted_28, [
            _createElementVNode("span", _hoisted_29, [
              _createElementVNode("span", _hoisted_30, [
                _createElementVNode("span", _hoisted_31, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/graphs/gra008.svg" })
                ])
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("span", { class: "d-flex flex-column" }, [
              _createElementVNode("span", { class: "fw-bolder text-gray-800 text-hover-primary fs-5" }, "Testing Account"),
              _createElementVNode("span", { class: "fs-6 fw-bold text-gray-400" }, "Use images to enhance time travel rivers")
            ], -1))
          ]),
          _createElementVNode("span", _hoisted_32, [
            _createVNode(_component_Field, {
              class: "form-check-input",
              type: "radio",
              name: "accountPlan",
              value: "3"
            })
          ])
        ])
      ])
    ])
  ]))
}