import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "fv-row mb-10" }
const _hoisted_3 = { class: "fv-row mb-10" }
const _hoisted_4 = { class: "fv-row mb-10" }
const _hoisted_5 = { class: "fv-row mb-10" }
const _hoisted_6 = { class: "fv-row mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "pb-10 pb-lg-12" }, [
      _createElementVNode("h2", { class: "fw-bolder text-dark" }, "Business Details"),
      _createElementVNode("div", { class: "text-gray-400 fw-bold fs-6" }, [
        _createTextVNode(" If you need more info, please check out "),
        _createElementVNode("a", {
          href: "#",
          class: "link-primary fw-bolder"
        }, "Help Page"),
        _createTextVNode(". ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label required" }, "Business Name", -1)),
      _createVNode(_component_Field, {
        name: "businessName",
        class: "form-control form-control-lg form-control-solid",
        value: "Keenthemes Inc."
      }),
      _createVNode(_component_ErrorMessage, {
        name: "businessName",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("label", { class: "d-flex align-items-center form-label" }, [
        _createElementVNode("span", { class: "required" }, "Shortened Descriptor"),
        _createElementVNode("i", {
          class: "fas fa-exclamation-circle ms-2 fs-7",
          "data-bs-toggle": "popover",
          "data-bs-trigger": "hover",
          "data-bs-html": "true"
        })
      ], -1)),
      _createVNode(_component_Field, {
        name: "businessDescriptor",
        class: "form-control form-control-lg form-control-solid",
        value: "KEENTHEMES"
      }),
      _createVNode(_component_ErrorMessage, {
        name: "businessDescriptor",
        class: "fv-plugins-message-container invalid-feedback"
      }),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "form-text" }, " Customers will see this shortened version of your statement descriptor ", -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { class: "form-label required" }, "Corporation Type", -1)),
      _createVNode(_component_Field, {
        name: "businessType",
        class: "form-select form-select-lg form-select-solid",
        "data-control": "select2",
        "data-placeholder": "Select...",
        "data-allow-clear": "true",
        "data-hide-search": "true",
        as: "select"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("option", null, null, -1),
          _createElementVNode("option", { value: "1" }, "S Corporation", -1),
          _createElementVNode("option", { value: "1" }, "C Corporation", -1),
          _createElementVNode("option", { value: "2" }, "Sole Proprietorship", -1),
          _createElementVNode("option", { value: "3" }, "Non-profit", -1),
          _createElementVNode("option", { value: "4" }, "Limited Liability", -1),
          _createElementVNode("option", { value: "5" }, "General Partnership", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_ErrorMessage, {
        name: "businessType",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label" }, "Business Description", -1)),
      _createVNode(_component_Field, {
        type: "text",
        name: "businessDescription",
        class: "form-control form-control-lg form-control-solid",
        rows: "3"
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[6] || (_cache[6] = _createElementVNode("label", { class: "fs-6 fw-bold form-label required" }, "Contact Email", -1)),
      _createVNode(_component_Field, {
        name: "businessEmail",
        class: "form-control form-control-lg form-control-solid",
        value: "corp@support.com"
      }),
      _createVNode(_component_ErrorMessage, {
        name: "businessEmail",
        class: "fv-plugins-message-container invalid-feedback"
      })
    ])
  ]))
}